/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-14 11:23:44
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询用户银行卡信息列表
export function listBankInfo (query) {
  return request({
    url: '/internal/recyclable/bankInfo/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询用户银行卡信息详细
export function getBankInfo (id) {
  return request({
    url: '/internal/recyclable/bankInfo/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增用户银行卡信息
export function addBankInfo (data) {
  return request({
    url: '/internal/recyclable/bankInfo',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改用户银行卡信息
export function updateBankInfo (data) {
  return request({
    url: '/internal/recyclable/bankInfo',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除用户银行卡信息
export function delBankInfo (id) {
  return request({
    url: '/internal/recyclable/bankInfo/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出用户银行卡信息
export function exportBankInfo (query) {
  return request({
    url: '/internal/recyclable/bankInfo/export',
     baseURL,
    method: 'get',
    params: query
  })
}
